import Layout from 'components/layout';
import SEO from 'components/seo';

import bannerBgSVG from 'assets/images/gallery/smaller_shutterstock_1389573218.svg';
import BlogList from 'components/Blog/BlogList';
import useIsClient from 'hooks/useIsClient';
import styled from 'styled-components';

import {initializeAndTrack} from 'gatsby-plugin-gdpr-cookies';
import LinkedInProfile from 'components/Social/LinkedIn';
import Quote from 'components/Quote/Quote';
import {Box, Center} from '@chakra-ui/react';

const BackGround = styled.div`
  background: url(${bannerBgSVG}) no-repeat center top / cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
`;

export default function IndexPage(): JSX.Element | null {
  const {isClient, key} = useIsClient();

  if (!isClient) {
    console.log('key: ', key);
    return null;
  }

  initializeAndTrack(window.location);

  return (
    <>
      <BackGround className='BackGround_BackGround' />
      <Layout>
        <>
          <SEO title='Softwareentwicklung in der Wildniss' />
          <BlogList />
          <Box>
            <Center>
              <LinkedInProfile />
            </Center>
          </Box>
          {/* <TechStack /> */}
          {/* <FeaturedSpace /> */}
          {/* <Gallery />
          <Pricing />
          <OurCustomer />
          <Blog /> */}
          {/* <Subscription /> */}
        </>
      </Layout>
    </>
  );
}
