import {Heading, Text} from '@chakra-ui/react';
import Article from 'components/Article/Article';
import Quote from 'components/Quote/Quote';
import {graphql, useStaticQuery} from 'gatsby';
import Banner from 'sections/Banner/Banner';

export default function BlogList(): JSX.Element {
  const {wpgraphql} = useStaticQuery(graphql`
    query MyQuery {
      wpgraphql {
        posts {
          edges {
            node {
              id
              content
              postId
              title
              tags {
                edges {
                  node {
                    id
                    name
                    tagId
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const pages = wpgraphql.posts.edges;

  return (
    <>
      {pages.map(page => {
        const hasTag = page.node.tags.edges.length > 0;
        const currentTag = hasTag ? page.node.tags.edges[0].node.name : 'default';
        const {title, content} = page.node;

        if (currentTag === 'quote') {
          return <Quote key={page.node.id} content={content} />;
        }

        if (currentTag === 'article') {
          return <Article key={page.node.id} content={content} title={title} />;
        }

        if (currentTag === 'banner') {
          return <Banner key={page.node.id} heading={page.node.title} content={content} />;
        }

        return (
          <section key={page.node.id}>
            <Heading>{page.node.title}</Heading>
            <Text as='p' dangerouslySetInnerHTML={{__html: content}}></Text>
          </section>
        );
      })}
    </>
  );
}
