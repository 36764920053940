import {Box, Heading} from '@chakra-ui/react';
import styled from 'styled-components';

export const ArticleContainer = styled(Box)<{$isMobile?: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 2rem 0;
`;

export const ArticleHeading = styled(Heading)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);

  margin: ${props => (props.isMobile ? '2rem 1rem' : '2rem 5rem')};
`;

export const ArticleStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: ${props => (props.isMobile ? '2rem 1rem' : '2rem 5rem')};

  h3 {
    font-weight: bold;
    font-size: 1.25em;
  }

  p {
    margin: 1rem 0;
  }

  ul {
    width: 150px;
    height: 150px;
  }
`;
