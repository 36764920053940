import {Text} from '@chakra-ui/react';
import {QuoteStyled} from './Quote.style';

type QuoteProps = {
  content: string | JSX.Element;
};

export default function Quote(props: QuoteProps): JSX.Element {
  const {content} = props;

  return (
    <QuoteStyled>
      {typeof content === 'string' ? (
        <Text as='p' dangerouslySetInnerHTML={{__html: content}} />
      ) : (
        content
      )}
    </QuoteStyled>
  );
}
