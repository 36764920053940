import {Text, useMediaQuery} from '@chakra-ui/react';

import {ArticleStyled, ArticleHeading, ArticleContainer} from './Article.style';
import LinkedInProfile from 'components/Social/LinkedIn';

type QuoteProps = {
  content: string | JSX.Element;
  title: string;
};

export default function Article(props: QuoteProps): JSX.Element {
  const {content, title} = props;

  const [isMobileWidth] = useMediaQuery('(max-width: 990px)');

  return (
    <>
      <ArticleContainer isMobile={isMobileWidth}>
        <ArticleHeading isMobile={isMobileWidth}>{title}</ArticleHeading>
        <ArticleStyled isMobile={isMobileWidth}>
          {typeof content === 'string' ? (
            <Text as='p' dangerouslySetInnerHTML={{__html: content}} />
          ) : (
            content
          )}
        </ArticleStyled>
      </ArticleContainer>
    </>
  );
}
