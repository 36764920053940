export default function LinkedInProfile(): JSX.Element {
  return (
    <div
      className='badge-base LI-profile-badge'
      data-locale='de_DE'
      data-size='large'
      data-theme='dark'
      data-type='HORIZONTAL'
      data-vanity='oliver-kinderknecht-45bb63201'
      data-version='v1'
    />
  );
}
